<template>
  <el-select v-model="syncModel" placeholder="" @change="$emit('update')">
    <el-option
      v-for="option in options"
      :key="option.id"
      :label="option.name"
      :value="option.id"
    />
  </el-select>
</template>

<script>
import { mapGetters } from 'vuex'
import { clone, filter } from 'lodash'
import dayjs from 'dayjs'

export default {
  name: 'OptionSelect',
  props: ['model', 'type'],
  computed: {
    ...mapGetters(['activitys', 'categorys']),
    syncModel: {
      get () { return this.model },
      set (data) { this.$emit('update:model', data) },
    },
  },
  watch: {
    activitys () { this.refresh() },
    groups () { this.refresh() },
    type () { this.refresh() },
  },

  data: () => ({
    options: [],
  }),

  mounted () {
    this.refresh()
  },

  methods: {
    refresh () {
      const activitys = filter(this.activitys, i => !dayjs().isAfter(dayjs(i.endAt)) && !i.isStorewideActivity)
      if (this.type === 'activity') this.options = clone(activitys)
      if (this.type === 'category') this.options = clone(this.categorys)
    },
  },
}
</script>
