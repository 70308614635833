<template>
  <section class="card-container">
    <a id="recommand-block" class="relative" href="#" style=" top: -90px" />
    <div class="card-content">
      <div class="flex justify-between items-center">
        <p class="title">精選推薦</p>
        <el-switch
          v-model="activated"
          active-text="顯示"
          inactive-text="不顯示"
        />
      </div>
      <p class="hint">可使用類別、活動，最少2個、最多4個推薦。</p>
      <el-form ref="form" :model="formData" :rules="formRules">
        <div v-for="(data, key, count) in formData" :key="key">
          <div class="flex w-full">
            <ActCateGroupSelect
              style="width: 100%"
              :data="formData[key].productType"
              :rulesProp="`${key}.productType`"
              @update="updateLinkData($event, count+1)"
            />
            <img v-if="count+1 > 2" src="@/assets/icon/trash.svg" class="cursor-pointer" @click="delBanner(count)">
          </div>
        </div>
      </el-form>
      <hr>

      <div class="add-btn-container">
        <AddButton v-if="bannerCount < 4" style="width: 444px" @click="addBanner" />
        <!-- <el-button v-if="bannerCount < 4" class="add-btn" @click="addBanner">新增 ＋</el-button> -->
      </div>
    </div>
  </section>
</template>

<script>
import { noEmptyRules } from '@/validation'
import ActCateGroupSelect from './ActCateGroupSelect.vue'
import { find, forEach, omit, some } from 'lodash'

export default {
  name: 'Recommand',
  components: { ActCateGroupSelect },
  props: ['pageConfig'],
  data: () => ({
    bannerCount: 2,
    activated: false,
    formData: {
      carousel_1: {
        productType: {
          ecommerceCategoryId: null,
          ecommerceActivityId: null,
          type: null,
        },
      },
      carousel_2: {
        productType: {
          ecommerceCategoryId: null,
          ecommerceActivityId: null,
          type: null,
        },
      },
    },
    formRules: {
      'carousel_1.productType.ecommerceCategoryId': [noEmptyRules()],
      'carousel_1.productType.ecommerceActivityId': [noEmptyRules()],
      'carousel_2.productType.ecommerceCategoryId': [noEmptyRules()],
      'carousel_2.productType.ecommerceActivityId': [noEmptyRules()],
    },
  }),
  computed: {
    activitys () {
      return this.$store.state['ecommerce-home'].activitys
    },
    categorys () {
      return this.$store.state['ecommerce-home'].categorys
    },
    compactData () {
      const banner = {}
      banner.carousel = []
      for (let c = 0; c < this.bannerCount; c++) {
        const carouse = `carousel_${c + 1}`
        const data = this.formData[carouse]
        const productType = omit(data.productType, ['ecommerceActivity', 'ecommerceCategory'])
        if (!this.activated && !some(productType, item => !item)) banner.carousel.push({ productType })
        else if (this.activated) banner.carousel.push({ productType })
      }
      return banner
    },
    composeData () {
      return {
        enable: this.activated,
        type: 'productTypeList',
        productTypeList: this.compactData,
      }
    },
  },
  watch: {
    composeData (data) {
      this.$emit('update', data)
    },
  },

  mounted () {
    this.syncConfig()
    this.$store.commit('SET_FORM_REF', {
      form: 'recommandForm',
      ref: this.$refs.form,
    })
  },

  methods: {
    clearNotExist (configData) {
      if (!configData) return []
      const avaliables = configData.filter(config => {
        config = config.productType
        if (config.type === 'ecommerceActivity') {
          const exist = find(this.activitys, { id: config.ecommerceActivityId })
          if (exist) return config
        }
        if (config.type === 'ecommerceCategory') {
          const exist = find(this.categorys, { id: config.ecommerceCategoryId })
          if (exist) return config
        }
      })
      return avaliables
    },
    updateLinkData (data, count) {
      this.$set(this.formData[`carousel_${count}`], 'productType', data)
      this.$forceUpdate()
    },
    addBanner () {
      if (this.bannerCount === 4) return
      this.bannerCount += 1
      this.$set(this.formData, `carousel_${this.bannerCount}`, {
        productType: {
          ecommerceCategoryId: null,
          ecommerceActivityId: null,
          type: null,
        },
      })

      this.$set(
        this.formRules,
        `carousel_${this.bannerCount}.productType.ecommerceCategoryId`,
        [noEmptyRules()],
      )
      this.$set(
        this.formRules,
        `carousel_${this.bannerCount}.productType.ecommerceActivityId`,
        [noEmptyRules()],
      )
    },
    delBanner (index) {
      const keys = Object.keys(this.formData)
      this.$delete(this.formData, keys[index])
      this.bannerCount -= 1
    },
    syncConfig () {
      const config = find(this.pageConfig, { type: 'productTypeList' })
      if (!config) return
      this.activated = config.enable
      const carousel = this.clearNotExist(config.productTypeList.carousel)
      forEach(carousel, (banner, index) => {
        this.bannerCount = index + 1
        if (!this.formData[`carousel_${index + 1}`]) {
          this.$set(this.formData, `carousel_${index + 1}`, {
            productType: {
              ecommerceCategoryId: null,
              ecommerceActivityId: null,
              type: null,
            },
          })
        }
        this.$set(this.formData[`carousel_${index + 1}`], 'productType', banner.productType)
      })
      if (carousel.length === 1) this.bannerCount = 2
    },
  },
}
</script>

<style lang="postcss" scoped>
.title {
  @apply font-medium text-[24px] leading-[34.75px] relative pl-[11px];
}
.title::before {
  content:'';
  @apply w-[3px] h-[28px] bg-primary-100 absolute left-0 ;
  top: calc(50% - 12px);
}

.hint {
  @apply text-sub text-gray-80 mt-[15px];
}

.add-btn-container {
  @apply flex justify-center items-center mt-[24px] mb-[12px] w-full;
}
.add-btn {
  @apply w-full max-w-[444px] rounded-[5px];
}
</style>
