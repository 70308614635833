<template>
  <section class="card-container">
    <a id="textStory-block" class="relative" href="#" style=" top: -90px" />
    <div class="card-content">
      <div class="flex justify-between items-center">
        <p class="title">文字故事</p>
        <el-switch
          v-model="formData.activated"
          active-text="顯示"
          inactive-text="不顯示"
        />
      </div>
      <el-form ref="form" :model="formData" :rules="formRules">
        <el-form-item label="主標" prop="title">
          <el-input v-model="formData.title" />
        </el-form-item>
        <el-form-item label="簡述" prop="description">
          <el-input v-model="formData.description" />
        </el-form-item>
      </el-form>
    </div>
  </section>
</template>

<script>
import { noEmptyRules } from '@/validation'
import { find } from 'lodash'

export default {
  name: 'TextStory',
  props: ['pageConfig'],
  data: () => ({
    formData: {
      activated: false,
      title: null,
      description: null,
    },
    formRules: {
      title: [noEmptyRules()],
      description: [noEmptyRules()],
    },
  }),
  computed: {
    compactData () {
      const text = {
        title: this.formData.title,
        description: this.formData.description,
      }
      return text
    },
    composeData () {
      return {
        enable: this.formData.activated,
        type: 'text',
        text: this.compactData,
      }
    },
  },
  watch: {
    composeData (data) {
      this.$emit('update', data)
    },
  },
  mounted () {
    this.syncConfig()
    this.$store.commit('SET_FORM_REF', {
      form: 'textStoryForm',
      ref: this.$refs.form,
    })
  },
  methods: {
    syncConfig () {
      const config = find(this.pageConfig, { type: 'text' })
      if (!config) return
      this.formData.activated = config.enable
      this.formData.title = config.text.title
      this.formData.description = config.text.description
    },
  },
}
</script>

<style lang="postcss" scoped>
.title {
  @apply font-medium text-[24px] leading-[34.75px] relative pl-[11px];
}
.title::before {
  content:'';
  @apply w-[3px] h-[28px] bg-primary-100 absolute left-0 ;
  top: calc(50% - 12px);
}

.hint {
  @apply text-sub text-gray-80 mt-[15px];
}
</style>
