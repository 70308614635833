<template>
  <main v-if="loaded" class="ecommerce-home-setting">
    <PageTitle title="編輯首頁設定" icon="chevron_left" hideBtn @iconClick="$router.push({ name: 'HomeView' })" />
    <EcommerceHomeAnchorTabs />
    <div style="margin-top: 20px">
      <BannerSetting :pageConfig="ecommerceHomePageConfig" @refresh="refresh" @update="updateData($event, 'banner')" />
      <ProductList :pageConfig="ecommerceHomePageConfig" @refresh="refresh" @update="updateData($event, 'productList')" />
      <Recommand :pageConfig="ecommerceHomePageConfig" @refresh="refresh" @update="updateData($event, 'recommand')" />
      <MediaReport :pageConfig="ecommerceHomePageConfig" @refresh="refresh" @update="updateData($event, 'mediaReport')" />
      <TextStory :pageConfig="ecommerceHomePageConfig" @refresh="refresh" @update="updateData($event, 'textStory')" />
      <Promotion :pageConfig="ecommerceHomePageConfig" @refresh="refresh" @update="updateData($event, 'marketing')" />
      <YoutubeLink :pageConfig="ecommerceHomePageConfig" @refresh="refresh" @update="updateData($event, 'youtubeLink')" />
    </div>

    <PageFixedFooter
      confirmBtn="儲存"
      @cancel="$router.push({ name: 'HomeView' })"
      @confirm="handleConfirm"
    />
  </main>
</template>

<script>
import EcommerceHomeAnchorTabs from '@/components/Tabs/EcommerceHomeAnchorTabs.vue'
import BannerSetting from './components/BannerSetting.vue'
import ProductList from './components/ProductList.vue'
import Recommand from './components/Recommand.vue'
import MediaReport from './components/MediaReport.vue'
import TextStory from './components/TextStory.vue'
import Promotion from './components/Promotion.vue'
import YoutubeLink from './components/YoutubeLink.vue'
import { mapGetters } from 'vuex'
import { forEach } from 'lodash'

export default {
  name: 'HomeSetting',
  components: {
    EcommerceHomeAnchorTabs, BannerSetting, ProductList, Recommand, TextStory, Promotion, YoutubeLink, MediaReport,
  },
  computed: {
    ...mapGetters(['shop', 'ecommerceHomePageConfig']),
    activitys () {
      return this.$store.state['ecommerce-home'].activitys
    },
    categorys () {
      return this.$store.state['ecommerce-home'].categorys
    },
    homePageConfig () {
      return this.ecommerceHomePageConfig
    },
    compactData () {
      const content = {
        areas: [],
      }
      for (const key in this.areas) {
        content.areas.push(this.areas[key])
      }
      return content
    },
  },
  data: () => ({
    disableUpdate: false,
    loaded: false,
    areas: {
      banner: {},
      productList: {},
      recommand: {},
      mediaReport: {},
      textStory: {},
      marketing: {},
      youtubeLink: {},
    },
  }),

  async mounted () {
    const [, getConfigErr] = await this.$store.dispatch('FindHomePageConfig')
    // if(getConfigErr) this.$message.error(getConfigErr)
    const [, getActError] = await this.$store.dispatch('GetActivity')
    if (getActError) this.$message.error(getActError)
    const [, getCateError] = await this.$store.dispatch('GetProductCategory')
    if (getCateError) this.$message.error(getCateError)
    this.loaded = true
  },
  methods: {
    async refresh () {
      this.$store.dispatch('FindHomePageConfig')
    },
    async handleConfirm () {
      const igonreList = [] // 如果區塊不啟用，忽略不檢查該區塊所有表單欄位
      if (!this.areas.banner.enable) igonreList.push('bannerForm')
      if (!this.areas.productList.enable) igonreList.push('productListForm')
      if (!this.areas.recommand.enable) igonreList.push('recommandForm')
      if (!this.areas.mediaReport.enable) igonreList.push('mediaReportForm')
      if (!this.areas.textStory.enable) igonreList.push('textStoryForm')
      if (!this.areas.marketing.enable) igonreList.push('marketingForm')
      if (!this.areas.youtubeLink.enable) igonreList.push('youtubeLinkForm')
      if (!await this.$store.dispatch('checkAllForm', igonreList)) {
        this.$message.error('尚有欄位未填寫。')
        return
      }
      const content = {
        areas: [],
      }
      forEach(this.areas, data => {
        const objKeys = Object.keys(data)
        console.log(objKeys)
        if (objKeys.length) content.areas.push(data)
      })

      // if(!content.areas.length) return this.$message.warning('請至少設定一個項目！')

      const [, err] = await this.$store.dispatch('updateHomePageSetting', {
        key: 'home',
        content,
      })

      if (err) return this.$message.error(err)
      this.$message.success('更新成功！')
      this.$router.push({ name: 'HomeView' })
    },
    updateData (data, type) {
      // if(type === 'banner') if(!data.banner.carousel.length) return
      // if(type === 'marketing') if(!data.banner.carousel.length) return
      // console.log(type, data)
      // if(type === 'textStory') if(!data.text.title || !data.text.description) return
      this.$set(this.areas, type, data)
    },
  },
}
</script>

<style lang="scss" scoped>
.message-tab-footer {
  position: fixed;
  display: flex;
  justify-content: flex-end;
  bottom: 0;
  left: 0;
  right: 0;
  gap: 30px;
  box-shadow: 0px -4px 8px rgba(47, 32, 27, 0.04);
  padding: 15px 60px !important;
  background: white;
  z-index: 2;
}
</style>
