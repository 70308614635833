<template>
  <div class="wrapper">
    <el-form-item label="選擇連結分類">
      <OptionSelect :model.sync="selectOption" @update="resetSelect" />
    </el-form-item>
    <el-form-item v-if="selectOption === 'category'" :label="optionLabel" :prop="`${rulesProp}.ecommerceCategoryId`">
      <ActCateSelect
        :model.sync="selectData"
        :type="selectOption"
        :objKey="objKey"
        @update="emitData"
      />
    </el-form-item>
    <el-form-item v-if="selectOption === 'activity'" :label="optionLabel" :prop="`${rulesProp}.ecommerceActivityId`">
      <ActCateSelect
        :model.sync="selectData"
        :type="selectOption"
        :objKey="objKey"
        @update="emitData"
      />
    </el-form-item>
  </div>
</template>

<script>
import OptionSelect from './OptionSelect.vue'
import ActCateSelect from './ActCateSelect.vue'
export default {
  name: 'ActCateGroupSelect',
  components: { OptionSelect, ActCateSelect },
  props: ['objKey', 'rulesProp', 'data'],
  data: () => ({
    selectOption: 'category',
    selectData: null,
  }),
  computed: {
    optionLabel () {
      const type = this.selectOption
      let label = ''
      if (type === 'activity') label = '選擇欲使用活動'

      if (type === 'category') label = '選擇欲使用類別'
      return label
    },
    compactData () {
      const type = this.selectOption
      const data = {}
      if (type === 'activity') {
        data.type = 'ecommerceActivity'
        data.ecommerceActivityId = this.selectData
      }

      if (type === 'category') {
        data.type = 'ecommerceCategory'
        data.ecommerceCategoryId = this.selectData
      }

      return data
    },
  },
  watch: {
    data () {
      if (this.data) {
        const type = this.data.type
        if (type === 'ecommerceCategory') this.selectOption = 'category'
        if (type === 'ecommerceActivity') this.selectOption = 'activity'
        this.selectData = this.data.ecommerceCategoryId || this.data.ecommerceActivityId
      }
    },
  },
  mounted () {
    if (this.data) {
      const type = this.data.type
      if (type === 'ecommerceCategory') this.selectOption = 'category'
      if (type === 'ecommerceActivity') this.selectOption = 'activity'
      this.selectData = this.data.ecommerceCategoryId || this.data.ecommerceActivityId
    }
  },
  methods: {
    emitData () {
      this.$emit('update', this.compactData)
    },

    resetSelect () {
      this.selectData = null
      this.emitData()
    },
  },
}
</script>

<style lang="postcss" scoped>
.wrapper {
  @apply grid grid-cols-2 gap-[12px] mb-[12px];
}
::v-deep .el-select {
  @apply w-full;
}
::v-deep .el-input {
  @apply w-full;
}
</style>
