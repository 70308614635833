<template>
  <section class="card-container">
    <a id="banner-block" class="relative" href="#" style=" top: -90px" />
    <div class="card-content">
      <div class="flex justify-between items-center">
        <p class="title">輪播 Banner</p>
        <el-switch
          v-model="activated"
          active-text="顯示"
          inactive-text="不顯示"
        />
      </div>
      <p class="hint">
        區分網頁以及手機版，每個版本最多三張。網頁版圖片尺寸建議1440x420像素，手機版圖片尺寸建議375x375像素。
      </p>

      <el-form ref="form" :model="formData" :rules="formRules">
        <div v-for="(data, key, count) in formData" :key="key" class="flex flex-col">
          <div class="flex justify-between">
            <div class="flex">
              <el-form-item :label="count ?'其它':'首圖'" style="margin-right: 56px" />
              <el-form-item class="col-form-item" style="margin-right: 80px" label="網頁版" :prop="`${key}.responsiveImage.webSizeImage`">
                <div v-if="formData[key].responsiveImage.webSizeImage" class="img-wrapper">
                  <div class="remove-btn">
                    <img
                      style="width:24px"
                      src="@/assets/icon/close.svg"
                      @click="deleteImg(formData[key], 'webSizeImage')"
                    >
                  </div>
                  <img height="90px" width="310px" :src="imgUrl(showImage(formData[key], 'webSizeImage'))" alt="">
                </div>
                <UploadButton
                  v-if="!formData[key].responsiveImage.webSizeImage"
                  :img="showImage(formData[key], 'webSizeImage')"
                  :isAvatar="false"
                  height="90px"
                  width="310px"
                  @change="changeRatio([1420, 420]), loadImg($event)"
                  @action="imgTarget = `${key}.responsiveImage.webSizeImage`"
                />
              </el-form-item>
              <el-form-item class="col-form-item" label="手機版" :prop="`${key}.responsiveImage.phoneSizeImage`">
                <div v-if="formData[key].responsiveImage.phoneSizeImage" class="img-wrapper">
                  <div class="remove-btn">
                    <img
                      style="width:24px"
                      src="@/assets/icon/close.svg"
                      @click="deleteImg(formData[key], 'phoneSizeImage')"
                    >
                  </div>
                  <img height="90px" width="90px" :src="imgUrl(showImage(formData[key], 'phoneSizeImage'))" alt="">
                </div>
                <UploadButton
                  v-if="!formData[key].responsiveImage.phoneSizeImage"
                  :img="showImage(formData[key], 'phoneSizeImage')"
                  :isAvatar="false"
                  height="90px"
                  width="90px"
                  @change="changeRatio([375, 375]), loadImg($event)"
                  @action="imgTarget = `${key}.responsiveImage.phoneSizeImage`"
                />
              </el-form-item>
            </div>

            <img v-if="count+1 > 1" src="@/assets/icon/trash.svg" class="cursor-pointer" @click="delBanner(count)">
          </div>

          <ActCateGroupSelect :data="formData[key].universalLink" :rulesProp="`${key}.universalLink`" @update="updateLinkData($event, count+1)" />

          <hr>
        </div>
      </el-form>
      <div class="add-btn-container">
        <AddButton v-if="bannerCount < 3" style="width: 444px" @click="addBanner" />
        <!-- <el-button v-if="bannerCount < 3" class="add-btn" @click="addBanner">新增 ＋</el-button> -->
      </div>
    </div>

    <ImageCropper
      v-if="uploadDialog"
      :image="uploadImg"
      :ratio="cropperRatio"
      @close="uploadDialog = false"
      @uploaded="getImage($event, false)"
    />
  </section>
</template>

<script>
import AddButton from '@/components/Button/AddButton.vue'
import ActCateGroupSelect from './ActCateGroupSelect.vue'
import UploadButton from '@/components/Button/UploadButton.vue'
import ImageCropper from '@/components/ImageCropper.vue'
import imgMixin from '@/mixin/image'
import { noEmptyRules } from '@/validation'
import { find, forEach, omit, some } from 'lodash'
import { imgSrc } from '@/utils/helper'

export default {
  name: 'BannerSetting',
  components: { ImageCropper, UploadButton, ActCateGroupSelect, AddButton },
  mixins: [imgMixin],
  props: ['pageConfig'],
  data: () => ({
    selectOption: null,
    selectData: null,
    bannerCount: 1,
    activated: false,
    cropperRatio: [1440, 420],
    formData: {
      carousel_1: {
        index: 1,
        responsiveImage: {
          webSizeImage: null,
          phoneSizeImage: null,
          webSizeImageId: null,
          phoneSizeImageId: null,
        },
        universalLink: {
          type: '',
          ecommerceActivityId: '',
          ecommerceCategoryId: '',
        },
      },
    },
    formRules: {
      // 'carousel_1.responsiveImage.webSizeImage': [noEmptyRules()],
      // 'carousel_1.responsiveImage.phoneSizeImage': [noEmptyRules()],
      'carousel_1.universalLink.ecommerceCategoryId': [noEmptyRules()],
      'carousel_1.universalLink.ecommerceActivityId': [noEmptyRules()],
    },
  }),
  computed: {
    activitys () {
      return this.$store.state['ecommerce-home'].activitys
    },
    categorys () {
      return this.$store.state['ecommerce-home'].categorys
    },
    compactData () {
      const banner = {}
      banner.carousel = []
      for (let c = 0; c < this.bannerCount; c++) {
        const carouse = `carousel_${c + 1}`
        const data = this.formData[carouse]
        if (data) {
          const webSizeImageId = data.responsiveImage.webSizeImage?.id
          const phoneSizeImageId = data.responsiveImage.phoneSizeImage?.id
          const universalLink = omit(data.universalLink, ['ecommerceActivity', 'ecommerceCategory'])
          banner.carousel.push({
            responsiveImage: {
              webSizeImageId,
              phoneSizeImageId,
            },
            universalLink,
          })
          // if (!this.activated && webSizeImageId && phoneSizeImageId && !some(universalLink, item => !item)) {
          //   banner.carousel.push({
          //     responsiveImage: {
          //       webSizeImageId,
          //       phoneSizeImageId,
          //     },
          //     universalLink,
          //   })
          // } else if (this.activated) {
          //   banner.carousel.push({
          //     responsiveImage: {
          //       webSizeImageId,
          //       phoneSizeImageId,
          //     },
          //     universalLink,
          //   })
          // }
        }
      }
      return banner
    },
    composeData () {
      return {
        enable: this.activated,
        type: 'banner',
        banner: this.compactData,
      }
    },
  },
  watch: {
    composeData (data) {
      this.$emit('update', data)
    },
    // composeData: {
    //   handler: function(data) {
    //     this.$emit('update', data)
    //   },
    //   deep: true,
    // }
  },
  mounted () {
    this.syncConfig()
    this.$store.commit('SET_FORM_REF', {
      form: 'bannerForm',
      ref: this.$refs.form,
    })
  },
  methods: {
    clearNotExist (configData) {
      if (!configData) return []
      const avaliables = configData.filter(config => {
        if (config.universalLink.type === 'ecommerceActivity') {
          const exist = find(this.activitys, { id: config.universalLink.ecommerceActivityId })
          if (exist) return config
        }
        if (config.universalLink.type === 'ecommerceCategory') {
          const exist = find(this.categorys, { id: config.universalLink.ecommerceCategoryId })
          if (exist) return config
        }
      })
      return avaliables
    },
    deleteImg (obj, type) {
      this.$set(obj.responsiveImage, type, null)
      this.$set(obj.responsiveImage, 'webSizeImageId', null)
      // obj.responsiveImage.webSizeImage = null
      this.$forceUpdate()
    },
    changeRatio (ratio) {
      this.cropperRatio = ratio
    },
    addBanner () {
      if (this.bannerCount === 3) return
      this.bannerCount += 1
      this.$set(this.formData, `carousel_${this.bannerCount}`, {
        responsiveImage: {
          index: this.bannerCount,
          webSizeImage: null,
          phoneSizeImage: null,
          webSizeImageId: null,
          phoneSizeImageId: null,
        },
        universalLink: {
          type: '',
          ecommerceActivityId: '',
          ecommerceCategoryId: '',
        },
      })

      // this.$set(this.formRules, `carousel_${this.bannerCount}.responsiveImage.webSizeImage`, [noEmptyRules()])
      // this.$set(this.formRules, `carousel_${this.bannerCount}.responsiveImage.phoneSizeImage`, [noEmptyRules()])
      this.$set(this.formRules, `carousel_${this.bannerCount}.universalLink.ecommerceCategoryId`, [noEmptyRules()])
      this.$set(this.formRules, `carousel_${this.bannerCount}.universalLink.ecommerceActivityId`, [noEmptyRules()])
    },
    delBanner (index) {
      const keys = Object.keys(this.formData)
      this.$delete(this.formData, keys[index])
      this.bannerCount -= 1
    },
    updateLinkData (data, count) {
      this.$set(this.formData[`carousel_${count}`], 'universalLink', data)
    },

    imgUrl (img) {
      return imgSrc(300, img)
    },

    syncConfig () {
      const config = find(this.pageConfig, { type: 'banner' })
      if (!config) return

      this.activated = config.enable

      forEach(this.clearNotExist(config.banner.carousel), (banner, index) => {
        this.bannerCount = index + 1
        if (!this.formData[`carousel_${index + 1}`]) {
          this.$set(this.formData, [`carousel_${index + 1}`], {})
          // this.formData[`carousel_${index + 1}`] = {}
        }
        this.$set(this.formData[`carousel_${index + 1}`], 'index', this.bannerCount)
        this.$set(this.formData[`carousel_${index + 1}`], 'responsiveImage', {})
        this.$set(this.formData[`carousel_${index + 1}`].responsiveImage, 'webSizeImage', banner.responsiveImage?.webSizeImage || null)
        this.$set(this.formData[`carousel_${index + 1}`].responsiveImage, 'webSizeImageId', banner.responsiveImage?.webSizeImageId || null)
        this.$set(this.formData[`carousel_${index + 1}`].responsiveImage, 'phoneSizeImage', banner.responsiveImage?.phoneSizeImage || null)
        this.$set(this.formData[`carousel_${index + 1}`].responsiveImage, 'phoneSizeImageId', banner.responsiveImage?.phoneSizeImageId || null)

        this.$set(this.formData[`carousel_${index + 1}`], 'universalLink', banner.universalLink)
      })
    },

    showImage (data, key) {
      if (data) {
        if (data.responsiveImage) return data.responsiveImage[key]
        return null
      }
      return null
    },
  },
}
</script>

<style lang="postcss" scoped>
.title {
  @apply font-medium text-[24px] leading-[34.75px] relative pl-[11px];
}
.title::before {
  content:'';
  @apply w-[3px] h-[28px] bg-primary-100 absolute left-0 ;
  top: calc(50% - 12px);
}

.hint {
  @apply text-sub text-gray-80 mt-[15px];
}

::v-deep .el-form-item__label {
  @apply text-left;
}

.col-form-item {
  @apply flex flex-col justify-start;
}

.add-btn-container {
  @apply flex justify-center items-center mt-[24px] mb-[12px] w-full;
}

.remove-btn {
  @apply cursor-pointer p-[4px] flex justify-end items-start;
  @apply absolute top-0 left-0 right-0 bottom-0 ;
  @apply text-[20px] text-white rounded-[4px];
  background: rgba(0, 0, 0, 0.2);
}

</style>
