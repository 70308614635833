<template>
  <section class="card-container">
    <a id="productList-block" class="relative" href="#" style=" top: -90px" />
    <div class="card-content">
      <div class="flex justify-between items-center">
        <p class="title">商品清單</p>
        <el-switch
          v-model="formData.activated"
          active-text="顯示"
          inactive-text="不顯示"
        />
      </div>
      <p class="hint">可使用類別、活動，預設顯示前8個商品。</p>
      <el-form ref="form" :model="formData" :rules="formRules">
        <ActCateGroupSelect :data="formData[`productList${count}`]" :rulesProp="`productList${count}`" :objKey="`productList${count}`" @update="updateLinkData" />
      </el-form>
    </div>
  </section>
</template>

<script>
import { noEmptyRules } from '@/validation'
import ActCateGroupSelect from './ActCateGroupSelect.vue'
import { find, omit } from 'lodash'

export default {
  name: 'ProductList',
  components: { ActCateGroupSelect },
  props: ['pageConfig'],
  data: () => ({
    count: 1,
    formData: {
      activated: false,
      productList1: {
        ecommerceActivityId: '',
        ecommerceCategoryId: '',
      },
    },
    formRules: {
      'productList1.ecommerceCategoryId': [noEmptyRules()],
      'productList1.ecommerceActivityId': [noEmptyRules()],
    },
  }),
  computed: {
    activitys () {
      return this.$store.state['ecommerce-home'].activitys
    },
    categorys () {
      return this.$store.state['ecommerce-home'].categorys
    },
    compactData () {
      const productList = {
        productType: omit(this.formData[`productList${this.count}`], ['ecommerceActivity', 'ecommerceCategory']),
      }
      return productList
    },
    composeData () {
      return {
        enable: this.formData.activated,
        type: 'productList',
        productList: this.compactData,
      }
    },
  },
  watch: {
    composeData (data) {
      this.$emit('update', data)
    },
  },

  mounted () {
    this.syncConfig()
    this.$store.commit('SET_FORM_REF', {
      form: 'productListForm',
      ref: this.$refs.form,
    })
  },
  methods: {
    clearNotExist (configData) {
      if (configData.type === 'ecommerceActivity') {
        const exist = find(this.activitys, { id: configData.ecommerceActivityId })
        if (exist) return configData
      }
      if (configData.type === 'ecommerceCategory') {
        const exist = find(this.categorys, { id: configData.ecommerceCategoryId })
        if (exist) return configData
      }
      return false
    },
    updateLinkData (data) {
      this.$set(this.formData, `productList${this.count}`, data)
    },
    syncConfig () {
      const config = find(this.pageConfig, { type: 'productList' })
      if (!config) return
      console.log(config)
      this.formData.activated = config.enable
      if (this.clearNotExist(config.productList.productType)) { this.formData.productList1 = config.productList.productType }
    },
  },
}
</script>

<style lang="postcss" scoped>
.title {
  @apply font-medium text-[24px] leading-[34.75px] relative pl-[11px];
}
.title::before {
  content:'';
  @apply w-[3px] h-[28px] bg-primary-100 absolute left-0 ;
  top: calc(50% - 12px);
}

.hint {
  @apply text-sub text-gray-80 mt-[15px];
}
</style>
