<template>
  <div class="anchor-tabs">
    <BorderTab>
      <el-tabs v-model="activeTab" type="card" @tab-click="tabClick">
        <el-tab-pane
          label="輪播Banner"
          name="banner"
        />
        <el-tab-pane
          label="商品清單"
          name="productList"
        />
        <el-tab-pane
          label="精選推薦"
          name="recommand"
        />
        <el-tab-pane
          label="媒體報導"
          name="mediaReport"
        />
        <el-tab-pane
          label="文字故事"
          name="textStory"
        />
        <el-tab-pane
          label="行銷圖片"
          name="marketing"
        />
        <el-tab-pane
          label="Youtube連結"
          name="youtube"
        />
      </el-tabs>
    </BorderTab>
  </div>
</template>

<script>
import BorderTab from './BorderTabs.vue'
export default {
  components: {
    BorderTab,
  },
  data: () => ({
    activeTab: 'banner',
  }),
  methods: {
    tabClick (e) {
      const a = document.createElement('a')
      a.href = `#${e.name}-block`
      a.click()
      setTimeout(() => {
        a.remove()
      }, 500)
      // this.$router.push(`#${e.name}-block`)
    },
  },
}
</script>

<style lang="postcss" scoped>
.anchor-tabs {
  @apply sticky top-[-20px] z-[2] left-0 right-0 relative;
}

.anchor-tabs::before {
  content: '';
  @apply absolute top-[-10px] left-[-30px] right-[-30px] bg-white ;
  @apply h-[108%];
  box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.07), inset 0px -1px 0px #DEDEDE;
}
</style>
