<template>
  <el-select v-model="syncModel" placeholder="" @change="$emit('update')">
    <el-option
      v-for="option in options"
      :key="option.type"
      :label="option.label"
      :value="option.type"
    />
  </el-select>
</template>

<script>
export default {
  name: 'OptionSelect',
  props: ['model'],
  data: () => ({
    options: [
      {
        label: '活動',
        type: 'activity',
      },
      // {
      //   label: '其他連結',
      //   type: 'link',
      // },
      {
        label: '類別',
        type: 'category',
      },
    ],
  }),
  computed: {
    syncModel: {
      get () { return this.model },
      set (data) { this.$emit('update:model', data) },
    },
  },
}
</script>
